<template>
    <div :class="['d-actions-list', `is-theme--${theme}`]">
        <div
            v-for="(schemeItem, schemeIndex) in scheme"
            :key="schemeIndex"
            :class="['d-actions-list__item', {'is-disabled': schemeItem.disabled }]"
            v-tooltip="schemeItem.disabled ? schemeItem.disabledLabel : undefined"
            @click="$emit('onClickAction', schemeItem.key)"
        >
            <div v-if="schemeItem.iconComponentName" class="d-actions-list__item-icon">
                <component :is="schemeItem.iconComponentName"/>
            </div>
            <div v-if="schemeItem.label" class="d-actions-list__item-label">
                {{ schemeItem.label }}
            </div>
        </div>
    </div>
</template>

<script>
import IconSelectAll from "@/components/Icons/IconSelectAll.vue";
import IconDownload from "@/components/Icons/IconDownload.vue";
import IconSend from "@/components/Icons/IconSend.vue";
import IconDelete from "@/components/Icons/IconDelete.vue";
import IconCloseV2 from '@/components/Icons/IconCloseV2.vue';

export default {
    name: "ActionsPanel",
    components: {
        IconSelectAll,
        IconDownload,
        IconSend,
        IconDelete,
        IconCloseV2
    },
    emits: ['onClickAction'],
    props: {
        scheme: {
            type: Array,
            default: () => []
        },
        theme: {
            type: String,
            default: 'default',
            validator: value => ['default', 'blue'].includes(value)
        },
    },
}
</script>


<style scoped lang="scss">
.d-actions-list {
    --d-actions-list--item-background: transparent;
    --d-actions-list--item-background--hover: var(--d-color--gray-200);
    --d-actions-list--label-color: var(--d-color--text-primary);
    --d-actions-list--label-color--hover: var(--d-color--text-primary);
    --d-actions-list--icon-color: var(--d-color--text-primary);
    --d-actions-list--icon-color--hover: var(--d-color--text-primary);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__item {
        cursor: pointer;
        padding: 7px 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: var(--d-actions-list--item-background);
        border-radius: var(--d-border-radius--2xs);
        transition: var(--d-transition--low);

        &.is-disabled {
            .d-actions-list__item {
                &-icon,
                &-label {
                    color: var(--d-color--gray-500);
                }
            }
        }

        &:not(.is-disabled):hover {
            background-color: var(--d-actions-list--item-background--hover);

            .d-actions-list__item {
                &-label {
                    color: var(--d-actions-list--label-color--hover);
                }

                &-icon {
                    color: var(--d-actions-list--icon-color--hover);
                }
            }
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        &-icon {
            display: flex;
            color: var(--d-actions-list--icon-color);
            width: 18px;
            height: 18px;
            transition: var(--d-transition--low);

            svg {
                width: 18px;
                height: 18px;
            }

            & + .d-actions-list__item-label {
                margin-left: 8px;
            }
        }

        &-label {
            color: var(--d-actions-list--label-color);
            font-size: var(--d-font-size--sm);
            font-weight: var(--d-font-weight--regular);
            line-height: 1;
            transition: var(--d-transition--low);
        }
    }

    &.is-theme {
        &--blue {
            --d-actions-list--item-background--hover: transparent;
            --d-actions-list--label-color: var(--d-color--blue-500);
            --d-actions-list--label-color--hover: var(--d-color--text-secondary);
            --d-actions-list--icon-color: var(--d-color--blue-500);
            --d-actions-list--icon-color--hover: var(--d-color--text-secondary);
        }
    }
}
</style>
