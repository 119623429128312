<template>
    <LayoutFullScreen :height-offset="60">
        <PriceIndex :kitId="selectedSetId"/>

        <RealtimeSystemMap/>
    </LayoutFullScreen>
</template>

<script>
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import MixinKitPanel from "@/mixins/MixinsPageBayuerdesk/MixinKitPanel";
import MixinTableFound from "@/mixins/MixinsPageBayuerdesk/MixinTableFound";
import LayoutFullScreen from "@/layouts/LayoutFullScreen.vue";
import PriceIndex from "@/components/pages/price/PriceIndex.vue";
import RealtimeSystemMap from "@/components/MapV2/RealtimeSystem/RealtimeSystemMap";

export default {
	name: "PagePrice",
	components: {
        PriceIndex,
        LayoutFullScreen,
        RealtimeSystemMap
	},
    mixins: [
        MixinApi,
        MixinKitPanel,
        MixinTableFound
    ],
    props: {
        selectedSetId: {
            type: String,
            default: ''
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
