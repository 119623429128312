<template>
    <BaseDropdown
        class="w-full d-datepicker"
        position="right"
    >
        <template #preview>
            <BaseButton
                class="d-datepicker__button"
                @click="changeVisible(true)"
            >
                <span class="d-datepicker__button-title">{{ title }}</span>
                <span class="d-datepicker__button-icon icon-tabler-icon-calendar-minus"></span>
            </BaseButton>
        </template>
        <template #content v-if="isOpened">
            <DatePicker
                :isMultiple="true"
                :accessDates="preparesAccessDates"
                :checkedDates="preparesSelectedDates"
                @approve="onSelectDates"
                @cancel="changeVisible(false)"
            />
        </template>
    </BaseDropdown>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton.vue";
import BaseDropdown from "@/components/Base/BaseDropdown.vue";
import DatePicker from "@/components/Base/DatePicker"

export default {
    name: "PriceEditDatepicker",
    components: {DatePicker, BaseDropdown, BaseButton},
    emits: ['onChange'],
    props: {
        selectedDates: {
            type: Array,
            default: () => []
        },
        accessDates: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        months: [
            'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
            'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
        ],
        isOpened: false,
    }),
    computed: {
        preparesSelectedDates() {
            let selectedDates = [];
            this.selectedDates.forEach(date => {
                let split = date.split('-');
                selectedDates.push({year: Number(split[0]), month: Number(split[1])})
            });

            return selectedDates;
        },
        preparesAccessDates() {
            const result = this.accessDates.map(date => ({ year: Number(date.split('-')[0]), month: Number(date.split('-')[1]) }));
            return result;
        },
        title() {
            if (this.preparesSelectedDates.length === 0) {
                return 'Выберите дату'
            } else {
                let uniqueMonths = []

                this.preparesSelectedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item-1])
                const result = uniqueMonths.join(', ')
                return `${ result }`
            }
        },
    },
    methods: {
        changeVisible(value = false) {
            this.isOpened = value;
        },
        onSelectDates(value) {
            this.$emit('onChange', value);
            this.changeVisible(false);
        }
    }
}
</script>

<style scoped lang="scss">
.d-datepicker {
    &__button {
        padding: 9px 12px 7px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--d-color--text-primary);
        font-size: var(--d-font-size--sm);
        font-weight: var(--d-font-weight--regular);

        &-icon {
            color: var(--d-color--gray-500);
            font-size: var(--d-font-size--xl);
        }
    }
}
</style>
