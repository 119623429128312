<template>
    <div class="d-badge">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: "BaseBadge",
    props: {
        text: {
            type: String,
            default: ""
        }
    }
}
</script>

<style scoped lang="scss">
.d-badge {
    --d-badge--color: var(--d-color--gray-500);
    --d-badge--background: var(--d-color--gray-200);

    padding: 4px 3px;
    color: var(--d-badge--color);
    font-size: var(--d-font-size--2xs);
    font-weight: var(--d-font-weight--regular);
    line-height: 1;
    background-color: var(--d-badge--background);
    border-radius: var(--d-border-radius--4xs);
}
</style>
