<template>
    <div class="d-layout-screen">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "LayoutFullScreen",
    props: {
        heightOffset: {
            type: Number,
            default: 0
        }
    },
    computed: {
        calculatedHeightOffset() {
            return this.heightOffset + 'px';
        }
    }
}
</script>

<style scoped lang="scss">
.d-layout-screen {
    --d-layout--height-offset: v-bind(calculatedHeightOffset);

    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: calc(100dvh - var(--d-layout--height-offset));
    max-height: calc(100dvh - var(--d-layout--height-offset));
}
</style>
