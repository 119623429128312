<template>
    <div class="d-selection-bar">
        <div v-if="foundText" class="d-selection-bar__count">
            Выбрано: <span>{{ foundText }}</span>
        </div>
        <div class="d-selection-bar__action" @click="$emit('onSelectAll')">
            Выбрать всё
        </div>
        <ActionsPanel
            class="d-selection-bar__actions-panel"
            :scheme="scheme"
            theme="blue"
            @onClickAction="(key) => $emit('onClickAction', key)"
        />
    </div>
</template>

<script>
import ActionsPanel from "@/components/Tables/ActionsPanel.vue";

export default {
    name: "SelectionBar",
    components: {ActionsPanel},
    emits: ['onClickAction', 'onSelectAll'],
    props: {
        count: {
            type: Number,
            default: 0
        },
        scheme: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        foundText() {
            if (typeof this.count !== "number") {
                return 0;
            }

            return this.count.toLocaleString('ru');
        }
    }
}
</script>


<style scoped lang="scss">
.d-selection-bar {
    padding: 3px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--d-color--blue-1000);
    border-radius: var(--d-border-radius--2xs);

    &__action {
        cursor: pointer;
        color: var(--d-color--blue-500);
        font-size: var(--d-font-size--sm);
        font-weight: var(--d-font-weight--regular);
        line-height: 1;
        transition: var(--d-transition--low);
        &:hover {
            color: var(--d-color--text-secondary);
        }
    }

    &__count {
        margin-right: 20px;
        color: var(--d-color--text-secondary);
        font-size: var(--d-font-size--sm);
        font-weight: var(--d-font-weight--regular);
        line-height: 1;

        span {
            font-weight: var(--d-font-weight--bold);
        }
    }

    &__actions-panel {
        margin-left: auto;
    }
}
</style>
