<template>
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.58325 2.3335C1.58325 1.91928 1.91904 1.5835 2.33325 1.5835H11.6666C12.0808 1.5835 12.4166 1.91928 12.4166 2.3335V3.6005C12.4165 4.10875 12.2145 4.59631 11.8551 4.95566C11.8551 4.95569 11.8551 4.95563 11.8551 4.95566L9.49992 7.31082V11.0835C9.49992 11.4063 9.29335 11.6929 8.98709 11.795L5.48709 12.9617C5.25838 13.0379 5.00696 12.9996 4.81139 12.8586C4.61581 12.7176 4.49992 12.4912 4.49992 12.2502V7.58178L2.08163 4.92167C1.76102 4.56893 1.5833 4.10933 1.58325 3.63266V2.3335ZM3.08325 3.0835V3.6325C3.08325 3.63253 3.08325 3.63247 3.08325 3.6325C3.08328 3.73607 3.12189 3.836 3.19154 3.91266C3.19156 3.91267 3.19153 3.91264 3.19154 3.91266L5.80487 6.78733C5.93038 6.92538 5.99992 7.10526 5.99992 7.29183V11.2096L7.99992 10.5429V7.00016C7.99992 6.80125 8.07894 6.61049 8.21959 6.46983L10.7944 3.895C10.8725 3.81692 10.9165 3.71094 10.9166 3.6005C10.9166 3.60044 10.9166 3.60055 10.9166 3.6005V3.0835H3.08325Z"/>
    </svg>
</template>

<script>
export default {
    name: "IconFilter"
}
</script>

<style scoped lang="scss">

</style>
