<template>
    <div class="d-price-filters">
        <div class="d-price-filters__content">
            <BaseTabList
                :is-loading="isLoading"
                :tab-list="tabsList"
                :tab-id-active="activeMode"
                theme="filter-outline"
                size="small"
                class="d-price-filters__tabs"
                @update:tabIdActive="onChangeTab"
            />
            <PriceFiltersList
                :is-loading="isLoading"
                :selected="filtersSelected"
                :scheme="filtersScheme"
                @onChange="onChangeFilters"
            />
        </div>
        <Chips
            class="d-price-filters__tags"
            :chips="chipsList"
            remove-all-label="Очистить фильтр"
            @remove="onRemoveFilter"
            @onRemoveAllChips="onRemoveAllFilters"
        />
    </div>
</template>

<script>
import BaseTabList from "@/components/Base/BaseTabList.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import PriceFiltersList from "@/components/pages/price/PriceFiltersList.vue";
import Chips from "@/layouts/components/Chips.vue";

export default {
    name: "PriceFilter",
    components: {Chips, PriceFiltersList, BaseTabList},
    props: {
        kitId: {
            type: String,
            default: ""
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        tabsList: [
            {
                id: 'month',
                title: 'Объединить период'
            },
            {
                id: 'period_uncover',
                title: 'Раздельно по месяцам'
            },
            {
                id: 'period',
                title: 'Рваные периоды'
            }
        ],
    }),
    computed: {
        ...mapGetters('editPriceFiltersStore', ['filtersScheme', 'filtersSelected', 'isLoadingFilters', 'activeMode']),
        chipsList() {
            let result = [];

            const filterKeys = Object.keys(this.filtersSelected);

            filterKeys.forEach(filterKey => {
                const foundSchemeItem = this.filtersScheme.find(item => item.key === filterKey);

                if (!foundSchemeItem) return;

                const valueByKey = this.filtersSelected[filterKey];

                if (Array.isArray(valueByKey)) {
                    valueByKey.forEach(valueItem => {
                        const findOptionByValue = foundSchemeItem.options.find(optionItem => optionItem.id === valueItem);

                        if (!findOptionByValue) return;
                        result.push({
                            key: filterKey,
                            ...findOptionByValue
                        });
                    });
                }
            });

            return result;
        }
    },
    mounted() {
        this.setFiltersSelected({});
    },
    methods: {
        ...mapMutations('editPriceFiltersStore', ['setFilterByKey', 'setActiveMode', 'removeFilterValueByKey', 'setFiltersSelected', 'setFiltersItems']),
        ...mapActions('editPriceFiltersStore', ['fetchFiltersData']),
        onChangeTab(value) {
            this.setActiveMode(value);
        },
        onRemoveFilter({key, id} = {}) {
            this.removeFilterValueByKey({key, value: id});
            this.fetchFiltersData(this.kitId);
        },
        onRemoveAllFilters() {
            this.setFiltersSelected({});
            this.fetchFiltersData(this.kitId);
        },
        onChangeFilters({key, value}) {
            this.setFilterByKey({key, value});
            this.fetchFiltersData(this.kitId);
        }
    },
}
</script>

<style scoped lang="scss">
.d-price-filters {
    &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__tabs {
        margin-right: 20px;
        width: fit-content;
        min-width: 320px;
        &.is-disabled {
            pointer-events: none;
        }
    }

    &__tags {
        margin: 12px 0 -12px;
    }
}
</style>
