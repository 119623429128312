<template>
    <div class="d-price-filters-panel">
        <template v-if="isLoading">
            <div class="d-price-filters-panel__item">
                <BasePreloaderSkeleton
                    class="d-price-filters-panel__loader is-small"
                />
            </div>
            <div class="d-price-filters-panel__item">
                <BasePreloaderSkeleton
                    class="d-price-filters-panel__loader"
                />
            </div>
            <div class="d-price-filters-panel__item">
                <BasePreloaderSkeleton
                    class="d-price-filters-panel__loader"
                />
            </div>
            <div class="d-price-filters-panel__item">
                <BasePreloaderSkeleton
                    class="d-price-filters-panel__loader"
                />
            </div>
            <div class="d-price-filters-panel__item">
                <BasePreloaderSkeleton
                    class="d-price-filters-panel__loader"
                />
            </div>
        </template>
        <template v-else>
            <div class="d-price-filters-panel__item">
                <div class="d-price-filters-panel__preview">
                    <IconFilter class="d-price-filters-panel__preview-icon"/>
                </div>
            </div>
            <div
                v-for="(schemeItem, schemeIndex) in scheme"
                :key="schemeIndex"
                class="d-price-filters-panel__item"
            >
                <DatePickerDropdown
                    v-if="schemeItem.componentName === 'DatePickerDropdown'"
                    :accessDates="filterItemAccessDates(schemeItem.options)"
                    :checkedDates="filterItemCheckedDates(selected[schemeItem.key])"
                    :default-title="schemeItem.label"
                    :is-show-icon="false"
                    :is-hide-selected-title="true"
                    is-multiple
                    position="left"
                    theme="filter"
                    @onApprove="(value) => onChangeDatepicker(schemeItem.key, value)"
                />
                <BaseSelectMultipleV2
                    v-else-if="schemeItem.componentName === 'BaseSelectMultipleV2'"
                    :options="schemeItem.options"
                    is-full-width
                    is-options-full-width
                    can-search
                    :is-hide-selected-title="true"
                    :is-show-arrow="false"
                    :default-title="schemeItem.label"
                    :value="selected[schemeItem.key]"
                    position="left"
                    theme="filter"
                    class="d-price-filters-panel__select"
                    @check="(value) => onChangeSelect(schemeItem.key, value)"
                />
            </div>
        </template>
    </div>
</template>

<script>
import BaseSelectMultipleV2 from '../../Base/BaseSelectMultipleV2.vue';
import DatePickerDropdown from "@/components/DatePickerDropdown.vue";
import UtilDate from "@/utils/UtilDate";
import IconFilter from "@/components/Icons/IconFilter.vue";
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton.vue";

export default {
    name: "PriceFiltersList",
    components: {
        BasePreloaderSkeleton,
        IconFilter,
        DatePickerDropdown,
        BaseSelectMultipleV2
    },
    emits: ['onChange', 'onRemoveAllChips', 'onRemoveChip'],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Object,
            default: () => {
            }
        },
        scheme: {
            type: Array,
            default: () => []
        }
    },
    computed: {},
    methods: {
        filterItemAccessDates(values = []) {
            const filterItemAccessDates = values.map(valueItem => UtilDate.getDatePickerObj(String(valueItem?.id)));
            return filterItemAccessDates;
        },
        filterItemCheckedDates(values = []) {
            const filterItemCheckedDates = values.map(valueItem => UtilDate.getDatePickerObj(String(valueItem)));
            return filterItemCheckedDates;
        },
        onChangeSelect(key, value = []) {
            this.$emit('onChange', {key, value});
        },
        onChangeDatepicker(key, value = []) {
            const datesSelected = value.map(date => UtilDate.fromDatepickerDate(date));
            this.$emit('onChange', {key, value: datesSelected});
        }
    }
}
</script>

<style scoped lang="scss">
.d-price-filters-panel {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    &__item {
        position: relative;

        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    &__preview {
        padding: 4px;
        display: flex;
        background-color: var(--d-color--white);
        border: 1px solid transparent;
        border-radius: var(--d-border-radius--4xs);

        &-icon {
            fill: var(--d-color--gray-500);
        }
    }

    &__select {
        ::v-deep .dropdown {
            min-width: 160px;
        }
    }

    &__loader {
        position: relative;
        width: 160px;
        height: 24px;

        &.is-small {
            width: 24px;
            height: 24px;
        }
    }
}
</style>
