<template>
    <div class="d-modifications">
        <template v-if="modificationsList.length">
            <div v-for="item in modificationsList" :key="item.id" class="d-modifications__item">
                <div class="d-modifications__card">
                    <div class="d-modifications__card-header">
                        <div class="d-modifications__card-title" contenteditable="true" spellcheck="false" @blur="(event) => onBlurTitle(event, item.name, item.id)">
                            {{ item.name }}
                        </div>
                        <div class="d-modifications__card-remove" @click="removeModification(item.id)">
                            <BaseIcon icon="icon-tabler-icon-trash" class="d-modifications__card-remove-icon" />
                        </div>
                    </div>
                    <div class="d-modifications__card-footer">
                        <div class="d-modifications__card-value">
                            {{ getSign(item) }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="d-modifications__empty">
            Модификаций нет
        </div>
    </div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ModificationsList",
    components: {BaseIcon},
    emits: ['onRemoveModification', 'onChangeModificationTitle'],
    computed: {
        ...mapGetters('editPricesModificationsStore', ['isLoadingModifications', 'modificationsList'])
    },
    methods: {
        ...mapActions('editPricesModificationsStore', ['removeModification', 'renameModification']),
        getSign(item) {
            let sign = item.change_value != '' ? parseInt(item.change_value).toLocaleString('ru') : '';
            if(item.change_type === 'enlarge'){
                sign = '+ ' + sign;
            }
            if(item.change_type === 'decrease'){
                sign = '- ' + sign;
            }
            if(item.value_type === 'percent'){
                sign = sign + ' %';
            }
            if(item.value_type === 'number'){
                sign = sign + ' ₽';
            }
            return sign;
        },
        onBlurTitle(event, oldValue, modificationId) {
            const value = event.target.textContent;
            if (value.trim() === '') {
                event.target.textContent = oldValue;
                return;
            }
            if (value.trim() === oldValue.trim()) return;

            this.renameModification({newTitle: value, modificationId});
        }
    }
}
</script>

<style scoped lang="scss">
.d-modifications {
    &__empty {
        color: var(--d-color--gray-500);
        font-size: var(--d-font-size--xs);
        font-weight: var(--d-font-weight--regular);
        line-height: 18px;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__card {
        padding: 12px;
        background-color: var(--d-color--yellow-secondary);
        border-radius: var(--d-border-radius--2xs);
        &-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        &-title {
            color: var(--d-color--text-primary);
            font-size: var(--d-font-size--sm);
            font-weight: var(--d-font-weight--regular);
            line-height: 1.143;
        }
        &-remove {
            margin: -6px -4px 0 8px;
            cursor: pointer;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            &-icon {
                color: var(--d-color--gray-500);
                font-size: var(--d-font-size--default);
            }
        }
        &-footer {
            margin-top: 4px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        &-value {
            color: var(--d-color--gray-500);
            font-size: var(--d-font-size--2xs);
            font-weight: var(--d-font-weight--regular);
            line-height: 1.143;
        }
    }
}
</style>
