<template>
    <div class="d-price-sidebar">
        <template v-if="isLoading">
            <BasePreloaderSkeleton
                class="d-price-sidebar__loader is-header"
            />
            <BasePreloaderSkeleton
                class="d-price-sidebar__loader is-body"
            />
            <BasePreloaderSkeleton
                class="d-price-sidebar__loader is-footer"
            />
        </template>
        <template v-else>
            <div class="d-price-sidebar__wrapper">
                <div class="d-price-sidebar__title">
                    Редактирование
                </div>
                <div class="d-price-sidebar__content">
                    <PriceEdit
                        :options="editOptions"
                        :selected-length="selectedRowsCount"
                        :is-loading="isLoadingCreateModification"
                        @onSubmit="onCreateModification"
                    />
                </div>
            </div>
            <div class="d-price-sidebar__wrapper is-scroll">
                <div class="d-price-sidebar__title">
                    Модификации
                    <BaseBadge :text="modificationsCount.toLocaleString('ru')" class="d-price-sidebar__badge"/>
                </div>
                <div class="d-price-sidebar__content">
                    <ModificationsList/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ModificationsList from "@/components/pages/price/ModificationsList.vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import PriceEdit from "@/components/pages/price/PriceEdit.vue";
import {mapActions, mapGetters} from "vuex";
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton.vue";

export default {
    name: "PriceSidebar",
    components: {
        BasePreloaderSkeleton,
        PriceEdit,
        BaseBadge,
        ModificationsList,
    },
    props: {
        kitId: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        priceEditValues: {},
    }),
    computed: {
        ...mapGetters('editPricesModificationsStore', ['modificationsCount', 'isLoadingCreateModification']),
        ...mapGetters('editPriceFiltersStore', ['selectedRows', 'filtersSelected', 'selectedRowsCount', 'accessedDates']),
        editOptions() {
            return {
                dates: [...this.accessedDates],
            }
        },
    },
    methods: {
        ...mapActions('editPricesModificationsStore', ['createModification']),
        async onCreateModification(formData = {}) {
            await this.createModification({
                set_id: this.kitId,
                selected: this.selectedRows,
                ...formData,
                filtered: {
                    set_id: this.kitId,
                    ...this.filtersSelected
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.d-price-sidebar {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--d-color--white);
    border-radius: var(--d-border-radius--2xs);

    &__wrapper {
        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &.is-scroll {
            overflow: auto;
        }
    }

    &__title {
        padding-bottom: 12px;
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--d-color--text-primary);
        font-size: var(--d-font-size--default);
        font-weight: var(--d-font-weight--medium);
        line-height: 1.25;
        background-color: var(--d-color--white);
    }

    &__badge {
        margin-left: 4px;
    }

    &__loader {
        position: relative;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
</style>
