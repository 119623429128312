<template>
    <div class="d-price">
        <PriceFilter
            class="d-price__filters"
            :kit-id="kitId"
            :is-loading="isLoadingPage"
        />
        <div class="d-price__content">
            <PriceTable
                class="d-price__table"
                :kit-id="kitId"
                :is-loading="isLoadingPage"
            />
            <PriceSidebar
                class="d-price__sidebar"
                :kit-id="kitId"
                :is-loading="isLoadingPage"
            />
        </div>
    </div>
</template>

<script>
import PriceSidebar from "@/components/pages/price/PriceSidebar.vue";
import PriceFilter from "@/components/pages/price/PriceFilter.vue";
import PriceTable from "@/components/pages/price/PriceTable.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "PriceIndex",
    components: {
        PriceTable,
        PriceFilter,
        PriceSidebar
    },
    mixins: {},
    props: {
        kitId: {
            type: String,
            default: ""
        }
    },
    data: () => ({
        isLoadingPage: true,
    }),
    computed: {
        ...mapGetters('kitsStore', ['findKitById', 'kitsList', 'reversedKitsList']),
    },
    mounted() {
        this.fetchPage();
    },
    methods: {
        ...mapMutations('editPricesModificationsStore', ['setKitId', 'addModification']),
        ...mapActions('editPricesModificationsStore', ['fetchModificationsList']),
        ...mapActions('kitsStore', ['fetchKitsList']),
        ...mapActions('editPriceFiltersStore', ['fetchFiltersData']),
        ...mapMutations('editPriceFiltersStore', ['setLoadingFilters']),
        /*
         * Первоначальный запросы при загрузке страницы
         */
        async fetchPage() {
            this.setLoadingFilters(true);
            try {
                await this.fetchKitsList();

                const foundKit = this.findKitById(this.kitId);

                if (!foundKit) {
                    // Роутим обратно на страницу наборов
                    return this.$router.push('/buyerdesk');
                }

                this.setKitId(this.kitId);
                await this.fetchFiltersData(this.kitId);
                await this.fetchModificationsList();
            } catch (error) {
                console.log(error);
                throw error;
            } finally {
                this.isLoadingPage = false;
                this.setLoadingFilters(false);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.d-price {
    --d-price--background: #ECF0F3;
    --d-price--sidebar-width: 240px;

    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100dvh - 60px);
    background-color: var(--d-price--background);

    &__filters {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    &__content {
        display: flex;
        align-items: stretch;
        flex-grow: 1;
        height: 0;
    }

    &__table {
        margin-right: 10px;
        flex-grow: 1;
        width: calc(100% - var(--d-price--sidebar-width));
    }

    &__sidebar {
        width: var(--d-price--sidebar-width);
    }
}
</style>
